import { BeatLoader } from "react-spinners";
import { styled } from 'styled-components';

const S = {
  Loading : styled.section`
    width : 100vw;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 3;
    background : #e7e7e799;

    display : flex;
    align-items : center;
    justify-content : center;
  `
}

const Loading = ({state}) => {
  return (
    <S.Loading>
      <BeatLoader color='#11192A' loading={state} size={50}/>
    </S.Loading>
  )
}

export default Loading;