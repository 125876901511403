import { styled } from 'styled-components';
import { plus } from '../assets';
import { useNavigate } from 'react-router-dom';

const S = {
  Card : styled.div`
    width : 23%;
    min-width : 230px;
    height : 150px;
    background-color : #f9f9f9;
    border-radius : 20px;
    border : 2px dashed #000000;
    filter: drop-shadow(2px 4px 4px #00000040);
    margin : 15px 0;
    padding : 10px 15px;
    box-sizing : border-box;

    display : flex;
    justify-content : center;
    align-items : center;

    cursor : pointer;

    @media(max-width : 1024px) {
      width : 100%;
    }
  `,
  Plus : styled.img`
    width : 100px;
    height : 100px;

    @media(max-width : 1024px) {
      width : 50px;
      height : 50px;
    }
  `
}

const PlusCard = () => {

  const navigate = useNavigate();

  return (
    <S.Card onClick={()=>navigate('/create')}>
      <S.Plus src={plus}/>
    </S.Card>
  )
}

export default PlusCard;