import { styled } from 'styled-components';
import { google, logo_black, naver } from '../assets';

const S = {
  Display : styled.section`
    width : 100vw;
    height : 100vh;

    display : flex;
    justify-content : center;
    align-items : center;
  `,
  Login : styled.div`
    width : 450px;
    height : 600px;
    background : #eeeeee;
    border-radius : 10px;
    border : 1px solid #000000;
    filter: drop-shadow(0px 4px 4px #00000040);

    display : flex;
    flex-direction : column;
    justify-content : space-around;
    align-items : center;

    @media(max-width : 1024px) {
      width : 80vw;
      height : 60vh;
      max-width : 450px;
      max-height : 600px;
    }
  `,
  Image : styled.img`
    max-width : 80%;

    &.google {
      width : 80%;
      cursor : pointer;
    }

    @media(max-width : 1024px) {
      
    }
  `
}

const Login = () => {

  const googlelogin = () => {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id='
    + process.env.REACT_APP_GOOGLE_KEY + '&redirect_uri=' 
    + process.env.REACT_APP_REDIRECT_URL
    + '/google'
    + '&response_type=code&scope=email profile'
    
    window.location.href = url

  }

  const naverlogin = () => {
    const naver_url = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
    + process.env.REACT_APP_NAVER_KEY + '&redirect_uri='
    + process.env.REACT_APP_REDIRECT_URL
    + '/naver'
    + '&state='
    + 'asd2222222';

    window.location.href = naver_url;
  }

  return (
    <S.Display>
      <S.Login>
        <S.Image src={logo_black}/>
        <div>
          <S.Image src={google} className='google' onClick={googlelogin}/>
          <S.Image src={naver} className='naver' onClick={naverlogin}/>
        </div>
      </S.Login>
    </S.Display>
  )
}

export default Login;