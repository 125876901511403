import { useEffect} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { styled } from 'styled-components';

const S = {
  Unity : styled.section`
    width : calc(100vw - 300px);
    height : 100%;
    display : flex;

    @media(max-width : 1024px){
      padding-top : 70px;
      width : 100vw;
    }
  `
}

const Server = () => {
  // 소녀
  // const unityContext = new UnityContext({
  //   loaderUrl : "build/UnityBuildTest.loader.js",
  //   dataUrl : "build/UnityBuildTest.data",
  //   frameworkUrl : "build/UnityBuildTest.framework.js",
  //   codeUrl : "build/UnityBuildTest.wasm"
  // });

  // gpu
  const {unityProvider, sendMessage} = useUnityContext({
    loaderUrl : "build2/TestBuild.loader.js",
    dataUrl : "build2/webgl.data",
    frameworkUrl : "build2/build.framework.js",
    codeUrl : "build2/build.wasm"
  });

  //test
  // const {unityProvider, sendMessage} = useUnityContext({
  //   loaderUrl : "build_pre/build.loader.js",
  //   dataUrl : "build_pre/webgl.data",
  //   frameworkUrl : "build_pre/build.framework.js",
  //   codeUrl : "build_pre/build.wasm"
  // });
  
  useEffect(() => {
    return () => {
      // sendmsg()
    }
  }, [])

  const sendmsg = () => {
    sendMessage("MyGameObject", "ChangeText", "Destroy")
  }

  return (
    <div>
      <S.Unity>
        {/* <button onClick={sendmsg}>버튼</button> */}
        <Unity unityProvider={unityProvider} style={{width:"100%", height:'auto', margin:'auto 0'}}/>
      </S.Unity>
    </div>
  )
}

export default Server;