import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Route = () => {
  
  const nat = useNavigate()

  useEffect(() => {
    nat('/dashboard')
  }, [])

  return (<></>)
}

export default Route;