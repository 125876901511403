import { keyframes, styled } from 'styled-components';
import { logo_white, menu } from '../assets';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../api/cookie';

const Nav_mobile = () => {
  const [isopen, setIsopen] = useState(false);
  const navigate = useNavigate();

  const [canclose, setCanclose] = useState('');

  const navi = (path) => {
    setCanclose('close')
    setIsopen(false)
    navigate(path);
  }

  const menuRef = useRef();
  const clickevent = (e) => {
    if(menuRef?.current && !menuRef?.current.contains(e.target)) {
      setIsopen(false)
    }
  }

  const setclose = () => {
    setCanclose('close')
    setIsopen(!isopen)
  }

  const logout = () => {
    localStorage.removeItem("Access_token")
    removeCookie('Refreshtoken')
    removeCookie('name')
    navigate('/login')
  }

  return (
    <>
      <S.Nav>
        <div/>
        <S.Logo src={logo_white} alt='logo' onClick={() => navi('/dashboard')}/>
        <S.Icon src={menu} alt='menu' onClick={setclose}/>
      </S.Nav>

      <S.Submenu className={isopen ? 'open' : canclose} onClick={(e) => clickevent(e)}>
        <S.Sub_item_group ref={menuRef}>
          <S.Sub_item onClick={() => navi('/dashboard')}>MY INSTANCE</S.Sub_item>
          <S.Sub_item onClick={() => navi('/create')}>CREATE INSTANCE</S.Sub_item>
          <S.Sub_item onClick={() => navi('/status')}>STATUS</S.Sub_item>
          <S.Sub_item onClick={logout}>LOGOUT</S.Sub_item>
        </S.Sub_item_group>
      </S.Submenu>
    </>
  )
}
const scale_up = keyframes`
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
`

const scale_down = keyframes`
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
`

const S = {
  Nav : styled.nav`
    width : 100vw;
    height : 70px;
    background-color : #11192a;

    position : fixed;
    left : 0;
    top : 0;
    z-index : 3;

    display : flex;
    justify-content : center;
  `,

  Logo : styled.img`
    height : 40px;
    margin : 15px 0;
  `,

  Icon : styled.img`
    width : 30px;
    height : 30px;
    
    margin : 20px 0;
    position : absolute;
    right : 15px;
  `,

  Submenu : styled.section`
    width : 100vw;
    height : calc(100vh - 70px);
    position : fixed;
    top : 69px;
    z-index : 3;

    transform : scaleY(0);

    &.open {
      animation : ${scale_up} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    } 
    
    &.close {
      animation : ${scale_down} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
  `,

  Sub_item_group :styled.section`
    height : 300px;
    background-color : #11192acc;
  `,

  Sub_item : styled.div`
    color : #ffffff;
    height : 75px;
    width : 100%;
    font-size : 30px;
    line-height : 75px;
  `

}
export default Nav_mobile;