import { Routes, Route, useLocation } from 'react-router-dom';
import {styled } from 'styled-components';
import { Create, Login, Main, OnlyLogin, Routing, Status } from '../pages';
import { Nav } from '../components';
import {PrivateRoute, LoginRoute } from './PrivateRoute';

const S = {
  App : styled.section`
    display : flex;
    position : relative;

    @media(max-width : 1024px) {
      flex-direction : column;
    }
  `
}


const Layout = () => {
  const location = useLocation();
  const hidenav = ["/login", "/signup", "/oauth/google","/oauth/naver", "/"]
  return(
    <S.App>
      {!hidenav.includes(location.pathname) && <Nav/>}
      <Routes>
        <Route path='/' element={<PrivateRoute component={<Routing/>}/>}/>
        <Route path='/dashboard' element={<PrivateRoute component={<Main/>}/>}/>
        <Route path='/create' element={<PrivateRoute component={<Create/>}/>}/>
        <Route path='*' element={<PrivateRoute component={<Routing/>}/>}/>
        <Route path='/login' element={<LoginRoute component={<Login/>}/>}/>

        
        <Route path='/oauth/:platform' element={<OnlyLogin/>}/>
        <Route path='/status' element={<Status/>} />
        {/* <Route path='/status' element={<KeepAlive><Status/></KeepAlive>} /> */}
        
        {/* <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<Routing/>}/>
        <Route path='/dashboard' element={<Main/>}/>
        <Route path='/create' element={<Create/>}/>
        <Route path='*' element={<Routing/>}/> */}
      </Routes>
    </S.App>
  )
}

export default Layout