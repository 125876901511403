import './App.css';
import './Font.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from './pages';

function App() {

  return (
    <div className="App">
      <Router>
        <Layout/>
      </Router>
    </div>
  );
}

export default App;
