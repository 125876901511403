import {styled} from 'styled-components';
import { logo_black } from '../assets';
import { useNavigate } from 'react-router-dom';

const S = {
  Display : styled.section`
    width : 100vw;
    height : 100vh;

    display : flex;
    justify-content : center;
    align-items : center;
  `,
  SignUp : styled.section`
    width : 90%;
    height : 90%;
    background : #eeeeee;
    border-radius : 10px;
    border : 1px solid #000000;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    padding : 40px;
    box-sizing : border-box;
    `,
  Image : styled.img`
    width : 240px;
    height : 116px;
  `,

  Policy : styled.section`
    width : 100%;
    background : #f0f0f0;
    border-radius : 10px;
    flex-grow : 1;
    text-align : start;
    box-sizing : border-box;
    font-family : 'SUIT';
    margin-top : 20px;

    & iframe {
      border : 0;
      background : #ffffff;
    }
  
  `,
  Button_sec : styled.section`
    width : 100%;
    height : 70px;
    margin-top : 30px;

    display : flex;
    justify-content : end;
  `,
  Button : styled.button`
    width : 50%;
    height : 70px;
    background : #ffffff;
    font-size : 20px;
    cursor : pointer;
    &:hover {
      background : #11192a;
      color : #ffffff;
    }
  `
}

const SignUp = () => {

  const iframePracyPart = () => {
    return {
      __html : '<iframe src="./Privacy_Policy.html" width="100%" height="100%"></iframe>'
    }
  }

  const navigate = useNavigate()
  
  return (
    <S.Display>
      <S.SignUp>
        <S.Image src={logo_black}/>
        <S.Policy dangerouslySetInnerHTML={iframePracyPart()}/>

        <S.Button_sec>
          <S.Button onClick={() => navigate('/')}>[개인정보 수집/이용 등에 관한 사항]을 잘 읽어보았으며 
          내용에 동의합니다.</S.Button>
        </S.Button_sec>
      </S.SignUp>
    </S.Display>
  )
}

export default SignUp;