import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { keyframes, styled } from 'styled-components';
import { logo_white } from '../assets';
import { getCookie, removeCookie } from '../api/cookie';
import { NavM } from '.';


const down_1 = keyframes`
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
`
const down_2 = keyframes`
  0% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
  100% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
  }
`

const down_3 = keyframes`
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
  }
`
const up_1 = keyframes`
  0% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
`
const up_2 = keyframes`
  0% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
  }
  100% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
`

const up_3 = keyframes`
  0% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
`

const S = {
  Nav : styled.nav`
    width : 300px;
    min-width : 300px;
    height : 100vh;
    background-color : #11192a;
    filter: drop-shadow(6px 0px 4px #00000040);

    position : sticky;
    left : 0;
    top : 0;

    @media(max-width : 1024px) {
      width : 100vw;
      height : 150px;
      
    }
  `,
  Menu : styled.section`
    margin-top : 10vh;
    height: 150px;
    width : 300px;
    position : relatvie;
  `,
  Background : styled.div`
    background : #f9f9f9;
    width : 100%;
    height: 75px;

    position : absolute;
    z-index : 1;

    &.down1 {
      -webkit-animation: ${down_1} 0.5s ease-in-out both;
      animation: ${down_1} 0.5s ease-in-out both;
    }

    &.up1 {
      -webkit-animation: ${up_1} 0.5s ease-in-out both;
      animation: ${up_1} 0.5s ease-in-out both;
    }

    &.down2 {
      -webkit-animation: ${down_2} 0.5s ease-in-out both;
      animation: ${down_2} 0.5s ease-in-out both;
    }

    &.up2 {
      -webkit-animation: ${up_2} 0.5s ease-in-out both;
      animation: ${up_2} 0.5s ease-in-out both;
    }

    &.down3 {
      -webkit-animation: ${down_3} 1s ease-in-out both;
      animation: ${down_3} 1s ease-in-out both;
    }

    &.up3 {
      -webkit-animation: ${up_3} 1s ease-in-out both;
      animation: ${up_3} 1s ease-in-out both;
    }
  `,
  Nav_item : styled.div`
    width : 100%;
    color : #f9f9f9;
    font-size : 24px;
    font-weight : 700;
    height : 75px;
    line-height: 75px;
    cursor : pointer;

    position : relative;
    z-index : 2;

    &.select {
      color : #000000;
      background : #ffffff;
    }
  `,
  Botton : styled.section`
    width : 300px;
    display : flex;
    flex-direction : column;
    align-items : center;

    position : absolute;
    bottom : 0;
  `,
  Logout : styled.div`
    height : 30px;
    color : #ffffff;
    font-size : 24px;
    font-weight : 700;
    line-height: 30px;
    margin : 30px 0;
    cursor : pointer;
  `,
  Info : styled.div`
    height : 50px;
    width : 180px;
    background: #ffffff;
    color : #000000;
    font-size : 24px;
    font-weight : 700;
    border-radius : 50px;
    line-height: 50px;
  `,
  Logo : styled.img`
    margin-top : 60px;
    cursor : pointer;
  `
}



const Nav = () => {

  const [select, setSelect] = useState('main');
  const navigate = useNavigate();

  const name = decodeURIComponent(getCookie('name'))
  const rule = decodeURIComponent(getCookie('rule'))
  const [before, setBefore ] = useState('main')

  const menuChange = (e) => {
    const path = window.location.pathname;
    if (path === '/create') {
      setBefore('create')
    } else if(path === '/status') {
      setBefore('status')
    } else {
      setBefore('main')
    }
    setSelect(e)
    if (e === 'main') {
      navigate('/dashboard')
    } else if(e ==='create'){
      navigate('/create')
    } else if(e ==='status') {
      navigate('/status')
    }
  }

  const logout = () => {
    localStorage.removeItem("Access_token")
    removeCookie('Refreshtoken')
    removeCookie('name')
    removeCookie('rule')
    navigate('/login')
  }

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/create') {
      setSelect('create')
      // menuChange('create')
    } else if(path === '/status') {
      setSelect('status')
    } else {
      setSelect('main')
    }
  }, [window.location.pathname])

  // mobile
  const isMobile = /Mobi/i.test(window.navigator.userAgent) || window.innerWidth <= 1024

  return (
    <>
    {isMobile ? 
      <NavM/>
      :
      <S.Nav>
        <S.Logo src={logo_white} onClick={() => navigate('/dashboard')}/>

        <S.Menu>
          {/* <S.Background className={classname()}/> */}
          <S.Nav_item className={select === 'main' ? 'select' : ''} onClick={() => menuChange('main')}>MY INSTANCE</S.Nav_item>
          <S.Nav_item className={select === 'create' ? 'select' : ''} onClick={() => menuChange('create')}>CREATE INSTANCE</S.Nav_item>
          {(rule !== 'guest' && rule !== 'undefined') &&
            <S.Nav_item className={select === 'status' ? 'select' : ''} onClick={() => menuChange('status')}>STATUS</S.Nav_item>
          }
        </S.Menu>
        
        <S.Botton>
          <S.Info>{name} 님</S.Info>
          <S.Logout onClick={logout}>Logout</S.Logout>
        </S.Botton>
      </S.Nav>
    }
    </>
  )
}

export default Nav;