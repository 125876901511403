import { styled } from 'styled-components';
import { dot } from '../assets';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../api/cookie';

const S = {
  Card : styled.div`
    width : 23%;
    min-width : 230px;
    
    height : 150px;
    background-color : #f9f9f9;
    border-radius : 20px;
    border : 1px solid #000000;
    filter: drop-shadow(2px 4px 4px #00000040);
    margin : 15px 2% 15px 0;
    padding : 10px 15px;
    box-sizing : border-box;

    display : flex;
    flex-direction : column;
    justify-content : space-between;

    @media(max-width : 1024px) {
      width : 100%;
    }
  
  `,

  Title : styled.h3`
    margin : 0;
    font-size : 18px;
    font-weight : 700;
    text-align : start;
    width : 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow : ellipsis;
  `,
  Time : styled.span`
    font-size : 18px;
  `,
  State : styled.div`
    width : 125px;
    height : 40px;
    background : #ffffff;
    border-radius : 20px;
    display : flex;
    align-items : center;

    & span {
      line-height : 40px;  
      font-weight : 600;
      font-size : 16px;
    }
  `,
  State_circle : styled.div`
    width : 20px;
    height : 20px;
    border-radius : 50%;
    background-color : yellow;
    box-sizing : border-box;
    margin : 0 5px;
    
    &.RUNNING {
      background-color : #00B412;
    }
    &.STOPPED {
      background-color : #FF7121;
    }
  `,
  Bottom : styled.section`
    display : flex;
    align-items : center;
    justify-content : space-between;

    
  `,
  Top : styled.section`
    display : flex;
    align-items : center;
    justify-content : space-between;

    position : relative;
    z-index : 1 ;
  `,

  Name : styled.div`
    text-align : start;
    margin-top : 5px;
  `,
  Detail : styled.img`
    width : 24px;
    height : 24px;
    cursor : pointer;
    position : relative;
  `,

  Submenu : styled.div`
    width : 130px;
    height: 70px;
    border : 1px solid #000000;
    background : #ffffffb3; 

    position : absolute;
    top : 30px;
    right : 10px;
    z-index : 3;
  `,
  Submenu_item : styled.div`
    width : 100%;
    height : 50%;
    line-height : 30px;
    cursor : pointer;

    &:hover {
      background : #d9d9d9cc;
    }
  `
}

const Card = ({data, modal_open}) => {

  const [detail, setDetail] = useState(false);
  const cardRef = useRef()
  const detailRef = useRef();
  const [status, setStatus] = useState(data?.status || 'RUNNING');
  
  const id = data.id
  // const id = '123'
  const name = data?.name

  useEffect(() => {
    function handleFocus(e) {
      if (cardRef.current && !cardRef.current.contains(e.target) && detailRef.current && !detailRef.current.contains(e.target)) {
        setDetail(false)
      }
    }
 
    document.addEventListener("click", handleFocus);
    return () => {document.removeEventListener("click", handleFocus)}
  }, [cardRef])


  const detailClick = (ele) => {
    const rule = getCookie('rule');
    if (rule === 'guest') {
      return;
    }
    
    setDetail(false)
    if(ele === "stop") {
      modal_open(id, ele, name)
      // setStartTime(false)
      // setStatus("STOPPED")
    } else if (ele === "restart" || ele === 'delete') {
      modal_open(id, ele, name)
    } else if (ele === 'server') {
      if (data?.terminal === '') {
        alert('관리자에게 문의하세요')
      } else {
        window.open(data?.terminal, '_blank')
      }
    }
  }

  const time = data?.value
  // const time = "02:02:06"
  const [hour, min, sec] = time.split(":")

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(data?.value);

  useEffect(() => {
    let interval = null;

    if(startTime) {
      interval = setInterval(() => {
        let ntime = (Math.floor((Date.now() - startTime) / 1000))
        let nsec = ( parseInt(sec) + ntime ) % 60
        let nmin = (parseInt(min) + Math.floor((parseInt(sec) + ntime)/60)) % 60
        let nhour = parseInt(hour) + Math.floor((parseInt(min) + Math.floor((parseInt(sec) + ntime)/60))/60)

        setElapsedTime(nhour.toString().padStart(2, '0') +":"+ nmin.toString().padStart(2, '0') + ":" + (nsec).toString().padStart(2, '0'))
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [startTime])

  useEffect(() => {
    if(status === 'RUNNING') {
      setStartTime(Date.now())
    }
  }, [])
  return (
    <S.Card>
      <div>
        <S.Top>
          <S.Title>{data?.name}</S.Title>
          <S.Detail src={dot} onClick={()=>setDetail(!detail)} ref={detailRef}/>
          { detail &&
            <S.Submenu ref={cardRef}>
              {/* <S.Submenu_item onClick={() => detailClick("stop")}>중지</S.Submenu_item>
              <S.Submenu_item onClick={() => detailClick("restart")}>재시작</S.Submenu_item> */}
              <S.Submenu_item onClick={() => detailClick("delete")}>삭제</S.Submenu_item>
              <S.Submenu_item onClick={() => detailClick("server")}>서버 접속</S.Submenu_item>
            </S.Submenu>
          }
        </S.Top>
        <S.Name>{data?.user}</S.Name>
        <S.Name>{data?.ssh}</S.Name>
      </div>
      <S.Bottom>
        <S.Time>{data?.time} {elapsedTime}</S.Time>
        <S.State>
          <S.State_circle className={status}/>
          <span>{status}</span>
        </S.State>
      </S.Bottom>
    </S.Card>
  )
}

export default Card;
