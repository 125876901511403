import { styled } from 'styled-components';
import { Card, Modal, PlusCard } from '../components';
import { useEffect, useState } from 'react';
import { getInstancelist } from '../api/api';
import { dropdown } from '../assets';
import Loading from '../components/Loading';

const S = {
  Main : styled.section`
    width : calc(100vw - 315px);
    box-sizing : border-box;
    padding : 60px 5vw;

    @media(max-width : 1024px) {
      width : 100vw;
      padding : 100px 5vw 30px ;
    }

  `,

  Title : styled.h2`
    margin : 0;
    font-size : 48px;
    font-weight : 700;
    text-align : start;

    @media(max-width : 1024px) {
      font-size : 30px;
    }
  `,

  Card : styled.section`
    display : flex;
    flex-wrap : wrap;
    justify-content : start;
  `,

  Price : styled.div`
    width : 100%;
    height : 150px;
    background : #ffffff;
    border : 1px solid #000000;
    box-sizing : border-box;

    display : flex;
    align-items : center;
    justify-content : center;
    font-size : 42px;

    @media(max-width : 1024px) {
      height : 100px;
    }
  `,

  Price_title : styled.section`
    width : 100%;
    height : 30%;
    background : #11192A;

    color : #ffffff;
    font-size : 28px;
    box-sizing : border-box;
    padding : 5px 20px;

    display : flex;
    align-items : center;
    justify-content : space-between;
    cursor : pointer;


    & .up {
      transform : rotate(180deg)
    }

  `,

  Price_sec : styled.section`
    width : 500px;

    position : fixed;
    bottom : -1px;
    right : 5vw;

    @media(max-width : 1024px) {
      width : 80vw;
    }
  `
}

const Main = () => {

  const [data, setData] = useState();

  const getdata = async () => {
    try{
      const res = await getInstancelist();
      setData(res.data.data)
    } catch(e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getdata()
  }, [])


  
  const [modal, setModal] = useState(false);
  const [modalid, setModalid] = useState("");
  const [modalname, setModalname] = useState("");
  const [cardstatus, setCardstatus] = useState("");
  const [loading, setLoading] = useState(false);

  const tmptimer = async () => {
    await new Promise(resolve => setTimeout(resolve, 15000))
  }

  const close = async (state) => {
    setModal(false);

    if(state === 'delete') {
      setLoading(true)

      try {
        await tmptimer();
        setLoading(false);
      }
      catch (err) {
        console.log(err)
      }
    }
    getdata()
  }

  const open = (id, sts, name) => {
    setCardstatus(sts)
    setModalid(id)
    setModalname(name)
    setModal(true)
  }

  const [price, setPrice] = useState(false)

  return (
    <S.Main>
      <S.Title>MY INSTANCE</S.Title>

      <S.Card>
        {data?.map((item, idx) => (
          <Card key={idx} data={item} modal_open={(id, sts, name) => open(id, sts, name)}/>
        ))}
        <PlusCard/>
      </S.Card>

      <S.Price_sec>
        <S.Price_title onClick={() => setPrice(!price)}>
          <span> 예상 결제 금액</span>
          <img src={dropdown} alt='drowpdown' className={price ? 'down' : 'up'}/>
        </S.Price_title>
        {price &&
          <S.Price>
            0원
          </S.Price>
        }
      </S.Price_sec>

      {modal &&
        <Modal closemodal={close} status={cardstatus} id={modalid} name={modalname}/>
      }

      {loading && (
        <Loading state={loading}/>
      )}

    </S.Main>
  )
}

export default Main;