import { Navigate } from "react-router-dom"
import { getCookie } from "../api/cookie"

const PrivateRoute = ({component}) => {
  const token = getCookie("Refreshtoken")
  const name = getCookie("name")
  return (
    (token && name && name!==undefined)?component:<Navigate to="/login" {...alert("로그인이 필요합니다.")}/>
  )
}

const LoginRoute = ({component}) => {
  const token = getCookie("Refreshtoken")
  const name = getCookie("name")

  return (
    (token && name && name!==undefined) ? <Navigate to="/"/> : component
  )
}

export {PrivateRoute, LoginRoute}