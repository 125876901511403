import { useEffect, useRef, useState } from 'react';
import { keyframes, styled } from 'styled-components';
import { createInstance, getSpec, getSpec_image } from '../api/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { getCookie } from '../api/cookie';
import { Ssh } from '../components';

const fadein = keyframes`
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
` 
const fadein_Y = keyframes`
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
` 

const fadeout = keyframes`
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
` 
const fadeout_Y = keyframes`
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
` 



const Create = () => {

  const [select, setSelect] = useState("");
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [warning, setWarning] = useState(false);
  const [gpu, setGpu] = useState("선택해주세요");
  const [storage, setStorage] = useState("선택해주세요");
  const [image, setImage] = useState("선택해주세요");
  const [sendimage, setSendimage] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [key, setKey] = useState(null);
  const [header, setHeader] = useState(null);
  const [filename, setFilename] = useState(null);
  const rule = getCookie('rule')

  const initSelect = (word) => {
    
    if(word === "" && select !=="") {
      setState("close")
      setTimeout(function() {
        setSelect("");
      },500)
    } else if (word === select) {
      if(state === 'close') {
        setState("open")
      }
    } else if(word ==="" && select === "") {
      //
    } else if(state !== "") {
      setState("close")
      setTimeout(function() {
        setSelect(word);
        setState("open")
      },200)
    } else {
      setSelect(word);
      setState("open")
    }
  }

  const [gpudata, setGpudata] = useState({
    "modelName" : "NVIDIA H100 80GB HBM3",
    "availableNumbers": [1, 2, 4, 8]
  });

  // 스토리지 100gb 500gb 1tb
  // 이미지는 cuda

  const [storagedata, setStoragedata] = useState(
    ["100GB", "500GB", "1TB"]
  )

  const [imagedata ,setImagedata] = useState(['Cuda12.1, Python3.9, PyTorch2.3.0'])

  const getspec = async () => {
    try{
      const res = await getSpec();
      setGpudata(res.data)

      const image = await getSpec_image();
      setImagedata(image.data)
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getspec();
  }, [])

  const selectgpu = (type, item) => {

    if (type === 'gpu') {
      setGpu(gpudata.modelName + '*'+ item)
    } else if (type === 'storage') {
      setStorage(item)
    } else if (type === 'image') {
      setImage(item)
    }
    setState("close")
  }

  const selecttype = (word) => {
    if (word === 'gpu') {
      return gpudata.availableNumbers;
    } else if (word==='storage') {
      return storagedata
    } else {
      return imagedata
    }
  }

  const tmptimer = async () => {
    await new Promise(resolve => setTimeout(resolve, 15000))
  }


  const createinstance = async () => {
    if (warning || name==='' || gpu === '선택해주세요' || storage === '선택해주세요' || image === 'image') {
      alert("모두 선택해주세요")
    }
    else {
      setLoading(true);
      try {
        
        const key = await createInstance({name, gpu, storage, image : sendimage})
        setKey(key.data);
        setHeader(key.headers['content-type'])
        setFilename(key.headers['content-disposition'])
        await tmptimer()
        
        setLoading(false);
        setDownloading(true);
        
      } catch (err) {
        setLoading(false);
        alert("인스턴스 생성에 실패했습니다.")
        console.log(err)
      }
    }
  }

  const isMobile = /Mobi/i.test(window.navigator.userAgent) || window.innerWidth <= 1024

  const tabRef = useRef();
  const buttonRef1 = useRef();
  const buttonRef2 = useRef();
  const buttonRef3 = useRef();
  const tabopen = (e) => {
    if(!tabRef?.current.contains(e.target) && (!buttonRef1?.current.contains(e.target)&&!buttonRef2?.current.contains(e.target)&&!buttonRef3?.current.contains(e.target))){
      setState('close')
      setSelect('')
    }
    else if (!tabRef?.current.contains(e.target)) {
      if(state === 'close') {
        setState('open')
      }
    }
  }

  const closessh = () => {
    setDownloading(false)
    navigate('/dashboard')
  }
  return (
    <S.Main onClick={(e) => {if(isMobile){tabopen(e)}}}>
      <S.Title>CREATE INSTANCE</S.Title>

      {loading && (
        <Loading state={loading}/>
      )}

      {downloading && (
        <Ssh closemodal={closessh} keydata={key} header={header} name={filename}/>
      )}

      <S.Form>
        <S.Form_section>
          <S.Form_title>NAME</S.Form_title>
          <S.Form_input_section>
            <S.Form_Input onClick={() => initSelect("") } onChange={(e) => {setName(e.target.value); if(e.target.value.includes('.')){setWarning(true)}else{setWarning(false)}}} className={warning ? 'warning' : ''}/>
            {warning &&
              <S.Form_warning>온점(.)을 포함하여 이름을 생성할 수 없습니다.</S.Form_warning>
            }
          </S.Form_input_section>
        </S.Form_section>
        <S.Form_section>
          <S.Form_title>GPU</S.Form_title>
          <S.Form_button ref={buttonRef1} onClick={() => initSelect("gpu")} className={select==="gpu" ? "select" : ""}>{gpu}</S.Form_button>
        </S.Form_section>
        <S.Form_section>
          <S.Form_title>STORAGE</S.Form_title>
          <S.Form_button ref={buttonRef2} onClick={() => initSelect("storage")} className={select==="storage" ? "select" : ""}>{storage}</S.Form_button>
        </S.Form_section>
        <S.Form_section>
          <S.Form_title>IMAGE</S.Form_title>
          <S.Form_button ref={buttonRef3} onClick={() => initSelect("image")} className={select==="image" ? "select" : ""}>{image}</S.Form_button>
        </S.Form_section>
      </S.Form>

      <S.Price_sec>
        <S.Price>
          {/* 추후 추가 예정 todo~ */}
          {/* 예상 금액 123,456원 / h */}
        </S.Price>
        <S.Create_button onClick={createinstance} disabled={rule==='guest' ? true : false}>CREATE</S.Create_button>
      </S.Price_sec>

      <S.Select className={state==="" ? "" : state==="close" ? "close" : "open"} ref={tabRef}>
        <S.Select_title>{select}</S.Select_title>
        { 
          selecttype(select).map((item, idx) => (
            <S.Select_item key={idx} onClick={() => {if(select==='image'){selectgpu(select, item.name); setSendimage(item.image)}else{selectgpu(select, item)}}}>
              {select === 'image' && item.name}
              {select === 'storage' && item}
              {select === 'gpu' && gpudata.modelName + ' * ' + item}
            </S.Select_item>
          ))
        }
        {selecttype(select).length < 1 && <div>생성 가능한 인스턴스가 없습니다</div>}
      </S.Select>
    </S.Main>
  )
}


const S = {
  Main : styled.section`
    width : calc(100vw - 315px);
    box-sizing : border-box;
    padding : 60px 85px;
    position : relative;
    overflow-x : hidden;

    @media(max-width : 1024px) {
      width : 100vw;
      padding : 100px 5vw 30px ;
    }

`,
  Title : styled.h2`
    margin : 0;
    font-size : 48px;
    font-weight : 700;
    text-align : start;

    @media(max-width : 1024px) {
      font-size : 30px;
    }
  `,

  Select : styled.section`
    width : 40%;
    height : calc(100vh - 140px);
    border : 2px solid #000000;
    border-radius : 20px 0 0 20px;

    position : absolute;
    right : -2px;
    top : 70px;

    transform: translateX(1000px);
    overflow : auto;

    &.close {
      -webkit-animation: ${fadeout} 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
      animation: ${fadeout} 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }
    
    &.open {
      -webkit-animation: ${fadein} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: ${fadein} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @media(max-width : 1024px) {
      position : fixed;
      width : 100%;
      height : 300px;
      bottom : 0;
      right : 0;
      top : auto;
      background-color : #ffffff;
      border-radius : 20px 20px 0 0;
      box-sizing : border-box;

      &.close {
        -webkit-animation: ${fadeout_Y} 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: ${fadeout_Y} 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
      }
    
      &.open {
        -webkit-animation: ${fadein_Y} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: ${fadein_Y} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
    }

  `,

  Select_title : styled.div`
    font-size : 36px;
    font-weight : bold;
    margin-bottom : 20px;
  `,

  Select_item : styled.div`
    border : 1px solid #000000;
    cursor : pointer;
    height : 20%;
    border-right : 0;
    border-left : 0;
    border-bottom : 0;
    margin : 0 20px;
    font-size : 24px;
    display : flex;
    align-items: center;
    justify-content : center;

    &:hover {
      background : #11192A1f;
    }

    &:last-child {
      border-bottom : 1px solid #000000;
    }


  `,
  Form : styled.section`
    @media(max-width : 1024px) {
      width : 100%;
    }
  `,
  Form_title : styled.div`
    font-size : 28px;
    width : 15%;
    text-align : start;

    @media(max-width : 1024px) {
      width : 40%;
      font-size : 23px;
    }
  `,
  Form_input_section : styled.section`
    width : 35%;

    @media(max-width : 1024px) {
      width : 60%;
    }
  `,
  Form_Input : styled.input`
    width : 100%;
    height : 50px;
    font-size : 24px;
    border-width : 0 0 2px;

    &.warning {
      border-color : red;
    }
  `,
  Form_warning : styled.div`
    color : red;
    margin-top : 5px;
  `,
  Form_button : styled.div`
    width : 35%;
    font-size : 24px;
    border : 1px solid #000000;
    cursor : pointer;

    display : flex;
    align-items : center;
    justify-content : center;

    &.select {
      border : 2px solid #000000;
    }

    @media(max-width : 1024px) {
      width : 70%;
    }
  `,
  Form_section : styled.div`
    display : flex;
    height : 100px;
    margin : 30px 0;
  `,
  Price_sec : styled.section`
    width : 50%;
    display : flex;
    justify-content : space-between;
    align-items : end;

    @media(max-width : 1024px) {
      width : 100%;
    }
  `,
  Price : styled.span`
    font-size : 32px;
  `,
  Create_button : styled.button`
    width : 250px;
    height : 65px;
    background : #11192a;
    border : 1px solid #a0a0a0;
    color : #ffffff;
    font-size : 24px;
    font-weight : 700;
    cursor : pointer;
  `
}


export default Create;